import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"


class PageTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPage

    return (
      <Layout background={ true } blog={ false }>
        <SEO title={ post.title } />
        <Introduction title={ post.title } />
        <div className="row shrink-width">
          <div className="column small-12 medium-10 large-8">
           <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
        </div>
        <Spacer space="4" />
      </Layout>
    )
  }
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
  }
`